import React from "react";

const QuantityInput = (props) => {
    
    const handleClick = () => {
        props.showQuantityModal(true);
    }

    return(
        <div style={{display:"flex", alignItems:"center", justifyContent: "center",gap: "1em", width: "100%",}}>
            <button
                style={{width:"fit-content", margin: "0.5em", padding: "0.2em", backgroundColor: "transparent", border: `1px solid ${props.textColor}`, borderRadius: "0.4rem"}}
                onClick={handleClick}
            >
                Quantity <span>{props.quantity} ⌵</span>
            </button>
            <span style={{color: props.textColor}}>
                {props.quantity > 1 ? `${props.price_value.toFixed(2)} per Item`: ""}
            </span>
        </div>
    );
}

export default QuantityInput;