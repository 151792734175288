/** @format */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

// http://127.0.0.1:3000/payment-link-generation?payment_id=NrpF_7a934efd-ab51-4508-bb18-7278eeb232a2&name=Total&description=Test&price=20&success_url=https://fintesa.co&user_id=123

import React, { useContext, useEffect, useState } from "react"; 
import { Row, Col, Button } from "react-bootstrap";

import PaypalIntegration from "../components/buttons/PaypalIntegrationButton";
import AxiosInstanceMENACartServer from "../components/axios/AxiosInstanceMENACartServer";
import ImageContext from "../ImageContext";

export default function PaymentLinkGeneration() {
  // Get the Query Parameter Information
  const location = window.location.search;
  const searchParams = new URLSearchParams(location);
  let paymentIdQuery = searchParams.get("payment_id");
  let nameQuery = searchParams.get("name");
  let descriptionQuery = searchParams.get("description");
  let priceQuery = searchParams.get("price");
  let successURLQuery = searchParams.get("success_url");
  let userIdQuery = searchParams.get("user_id");
  const {PaymentImages} = useContext(ImageContext)

  // Validate the Query Parameters
  function validateQueryParameters() {
    if (!paymentIdQuery) {
      alert("Payment id is not provided");
      return;
    }
    if (!priceQuery) {
      alert("Price is not provided");
      return;
    }
    if (isNaN(priceQuery)) {
      alert("Price provided is not valid");
      return;
    }
    if (parseFloat(priceQuery) < 0.5) {
      alert("Price provided is less than $0.5");
      return;
    }
    if (successURLQuery && !isValidHttpUrl(successURLQuery)) {
      alert("Success URL provided is not valid");
      return;
    }
    getMerchantIntegrations();
  }
  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
  useEffect(() => {
    validateQueryParameters();
  }, []);

  const [paypalIntegration, setPaypalIntegration] = useState(false);
  function getMerchantIntegrations() {
    AxiosInstanceMENACartServer.get(
      `/integrations/retrieve/${paymentIdQuery}`
    ).then((res) => {
      if (res.data[0]) {
        res.data.map((integration) => {
          if (
            integration.name === "paypal" &&
            integration.integration_status === "completed"
          ) {
            setPaypalIntegration(true);
          } else {
            createPaymentLink();
          }
        });
      } else {
        createPaymentLink();
      }
    });
  }

  function createPaymentLink() {
    AxiosInstanceMENACartServer.post(
      `/create-product-return-payment-link/${paymentIdQuery}`,
      {
        name: nameQuery,
        description: descriptionQuery,
        price: priceQuery,
        success_url: successURLQuery,
        user_id: userIdQuery,
      }
    ).then((res) => {
      if (res.data === "The Payment Id Provided is Not Valid") {
        alert("The Payment Id Provided is Not Valid");
      } else {
        window.location.replace(res.data);
      }
    });
  }

  return (
    <Row
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        margin: "0",
        padding: "0",
      }}
    >
      <Col
        lg={6}
        style={{
          justifyContent: "center",
          margin: "auto",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          padding: "0",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {/* PayPal Button */}
        {paypalIntegration ? (
          <>
            {/* Pay with Card Button */}
            <Row
              style={{
                width: "100%",
                margin: "0",
                height: "55px",
                marginBottom: "30px",
              }}
            >
              <Button style={{ fontSize: "20px" }} onClick={createPaymentLink}>
                Pay with Card
              </Button>
            </Row>

            {/* PayPal Button */}
            <Row>
              <PaypalIntegration />
            </Row>
          </>
        ) : (
          // Loading Image
          <img
            src={PaymentImages["Loading.gif"]}
            alt="loading..."
            width="150px"
          />
        )}
      </Col>
    </Row>
  );
}
