import { useContext } from 'react';
import ImageContext from '../../ImageContext';

const BackgroundStyle = () => {
  const {PaymentImages} = useContext(ImageContext)
  return (
    <div>
      <img
    alt='Back_Benefit_M2'
    src={PaymentImages['black.png']}
    style={
      window.innerWidth > 768
        ? {
              position: 'absolute',
              top: '0px',
              padding: '0 0 0 0',
              margin: '0',
              width: '100%',
              zIndex: '1',
              marginTop: '-150px',
          }
        : {
              position: 'absolute',
              top: '0px',
              padding: '0 0 0 0',
              margin: '0',
              width: '100%',
              zIndex: '1',
          }
      }
    />
    </div>
  )
}

export default BackgroundStyle;
