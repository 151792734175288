import React, { useState, useEffect, useContext } from 'react'
import { Row ,Col ,Button, Form, Spinner} from 'react-bootstrap'
import Fintesa from "../images/Fintesa.png";
import BackgroundStyle from '../components/images/BackgroundStyle';
import IntegrationVerification from '../components/module/IntegrationVerification';
import { toast } from "react-toastify";
import AxiosInstanceUserServer from '../components/axios/AxiosInstanceUserServer';
import { useParams } from 'react-router-dom';
import IntegrationsMessage from '../components/modals/IntegrationsMessage';
import { BiArrowBack } from 'react-icons/bi';
import ImageContext from '../ImageContext';

const IntegrationEmail = ({setVerfied, onPaymentTypeChange, setProductName, setProductDescription, setClientSecret, setPriceObject, email, setEmail}) => {
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [show , setShow ] = useState( false )
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [integrationName, setIntegrationName] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const {productId,priceId} = useParams();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [showIntegrationsMessageModal, setShowIntegrationsMessageModal] = useState(false);

    const {PaymentImages} = useContext(ImageContext)

    useEffect(() => {
      const productAndPrice = productId+"/"+priceId;
      if (productAndPrice === process.env.REACT_APP_PAYPAL_RECURRING) {
        setIntegrationName("PayPal");
        setPaymentType("recurring");
      } 
      if ( productAndPrice === process.env.REACT_APP_PAYPAL_ONE_TIME) {
        setIntegrationName("PayPal");
        setPaymentType("one_time");
      } if (productAndPrice === process.env.REACT_APP_SHOPIFY_ONE_TIME) {
        setIntegrationName("Shopify");
        setPaymentType("one_time");
      } else if (productAndPrice === process.env.REACT_APP_SHOPIFY_RECURRING) {
        setIntegrationName("Shopify");
        setPaymentType("recurring");
      }
    }, [priceId]);
    useEffect(() => {
      onPaymentTypeChange(paymentType);
    }, [paymentType])

    const checkPlan = () => {
      AxiosInstanceUserServer.post('/integrations/check-plan', JSON.stringify({
        email: email,
        integration_name: integrationName,
        payment_type: paymentType,
        price_id: priceId,
        name: name
      })).then((res) => {
        if (res.data === "There is No Account Attached With This Email") {
          setTitle("Register Your Business");
          setMessage(
            <>
              <p>{res.data}</p>
              <p>Choose a plan that fits your needs from our <a href={process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE+"/pricing"}>pricing page</a></p>
            </>
          );
          setShowIntegrationsMessageModal(true);
        } else if (res.data === "Already Subscribed") {
          setTitle("Already Subscribed");
          setMessage(
            <>
              <p>{res.data}</p>
              <p>Login to your account (<a href={process.env.REACT_APP_FRONTEND_URL_USER}>Login</a>)</p>
            </>
          );
          setShowIntegrationsMessageModal(true);
        } else if (res.data === "The PayPal Integration is Already Included in The Current Plan") {
          setTitle("Already has a PayPal Integration");
          setMessage(
            <>
              <p>{res.data}</p>
              <p>Login to your account (<a href={process.env.REACT_APP_FRONTEND_URL_USER}>Login</a>)</p>
            </>
          );
          setShowIntegrationsMessageModal(true);
        } else if (res.data && res.data.clientSecret) {
          setClientSecret(res.data.clientSecret)
          setPriceObject(res.data.price_object)
          setProductDescription(res.data.product_description)
          setProductName(res.data.product_name)
          setVerfied(true);
        } 
        setShowVerificationModal(false);
      })
      .catch((err) => {
        console.error(err);
      })
    }

    const handleOpen = (event) => {
        event.preventDefault();
        setLoading(true);
        sendVerificationEmail();
        setValidated(true);
    }

    const sendVerificationEmail = () => {
      AxiosInstanceUserServer.post('/plans/send-verification-code',{email:email , product_id: productId})
        .then( (res)=> {
          if (res.data === "A Verification Code has Been Sent to your Email, Check Your Inbox, Spam, or Junk!") {
            if (email && name) {
              setShowVerificationModal(true);
              setShow(true);
            } else{
                toast.error("Please fill fields or add email correctly");
            }
          }
        }).catch( error => {
          console.error(error);
        }).finally (() => {
          setLoading(false);
        })
    }

    const handleUpdateEmail = (e) => {
        e.preventDefault();
        const emailWithoutSpicalChar = e.target.value.match(/[!#$%^&*(),?":{}|<>]/);
    
        if (!emailWithoutSpicalChar) { 
          let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
          const updateEmail = e.target.value.toLowerCase();
    
          if (updateEmail.match(mailFormat)) {
            setEmail(updateEmail);
          } else {
            setEmail('');
          }
        }
      };

  return (
    <>
    {showIntegrationsMessageModal && <IntegrationsMessage show={showIntegrationsMessageModal} setShow={setShowIntegrationsMessageModal} title={title} message={message} />}
    <BackgroundStyle />
    <div className='fintesa-integration-container'>
      <Row 
    //   className="justify-content-center align-items-center" 
      style={{position: 'relative',display:'flex', minHeight: '100vh',width:'100%', justifyContent:'center',justifyItems:'center', alignItems:'center' }}>
        <Col style={{zIndex: '1'}} xs={6} md={4} lg={4}>
            <Form style={{backgroundColor:'white', width:'100%', display:'flex',justifyContent:'space-evenly', alignItems:'center', minHeight:'400px', flexDirection:'column', padding:'3rem 2rem', borderRadius:'1rem'}}>
                <div style={{alignSelf: "flex-start"}}>
                  <a style={{textDecoration: "none", color: "black", fontSize: "1.5rem"}} href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/pricing#integrations-solution`}>
                    <BiArrowBack />
                  </a>
                </div>
                <div style={{maxWidth:'25%'}}>
                    <img src={PaymentImages['Fintesa.png']} style={{maxWidth:'100%'}} />
                </div>

                <h3 style={{ margin: '1rem' }}>Add your information</h3>

                <Form.Group style={{width:'80%'}} controlId="email" className='mb-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                // value={email}
                placeholder='Your account email'
                type='email'
                required
                name='email'
                onChange={ handleUpdateEmail } 
                disabled={loading}
                // onChange={(e) => setEmail(e.target.value)}
                />
                </Form.Group>

                <Form.Group style={{width:'80%'}} controlId="name" className='mb-3'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                value={name}
                placeholder='Your name'
                type='text'
                required
                onChange={(e) => setName(e.target.value)}
                disabled={loading}
                />
                </Form.Group>

                <Button disabled={loading} style={{width:'30%'}} onClick={handleOpen}>{loading ? <Spinner size='sm' variant='light' animation='border' /> : "Next"}</Button>
                
            </Form>
            {showVerificationModal &&
                <IntegrationVerification checkPlan={checkPlan} sendVerificationEmail={sendVerificationEmail} handleOpen={handleOpen} setVerfied={setVerfied} email={email} name={name} show={show} setShow={setShow}/>}
        </Col>
      </Row>
      
    </div>
    </>
  )
}

export default IntegrationEmail
