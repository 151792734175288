import React, { useEffect, useState } from "react";
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { Toast } from "react-bootstrap";
import PaymentCompleted from "./PaymentCompleted";
import QuantityInput from "../inputs/QuantityInput";
import PayForm from "./PayForm";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { Spinner, Alert } from "react-bootstrap";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import QuantityModal from "../modals/QuantityModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayFormSkeleton from "../skeletons/PayFormSkeleton";
import { useContext } from "react";
import { PaymentContext } from "./PaymentContext";
import ImageContext from "../../ImageContext";

export default function CheckoutForm(props) {
  const {
    isStylesLoading,
    paymentCompleted,
    specialCurrencies,
    errorToast,
    errorAlert,
    logo,
    textColor,
    backgroundColor,
    product,
    price,
    quantityModalShow,
    setQuantityModalShow,
    quantity, setQuantity
  } = useContext(PaymentContext);
  const [email, setEmail] = useState(props.email ? props.email : "");
  
  const {PaymentImages} = useContext(ImageContext)

  //class to body
  document.body.classList.add("payment_intent_style");

  async function LoadStripe() {
    let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
    return await loadStripe(stripe_publish_key, {
      stripeAccount: props.account_id,
    });
  }
  const stripePromise = LoadStripe();

  
  let price_value = 0;
  let currency = "";
  if (price.metadata && price.metadata.currency_type === "jod") {
    let factor = 0.708;
    if (props.payment_uuid === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
      factor = 0.731;
    }
    price_value = (price["unit_amount"] * factor) / 100;
    currency = "jod";
  } else if (specialCurrencies.includes(price["currency"])) {
    price_value = price["unit_amount"];
    currency = price["currency"];
  } else {
    price_value = price["unit_amount"] / 100;
    currency = price["currency"];
  }
  return (
    <>
      {quantityModalShow && (
        <QuantityModal
          price={price}
          show={quantityModalShow}
          setShow={setQuantityModalShow}
          setQuantity={setQuantity}
          quantity={quantity}
          createPaymentIntent={props.createPaymentIntent}
          price_value={price_value}
        />
      )}
      {isStylesLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {product["id"] && price["id"] && (
            <div className="container_payments">
              <Toast
                style={{
                  position: "absolute",
                  top: "0px",
                  height: "30px",
                  width: " 100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  backgroundColor: "rgb(230, 159, 159)",
                  border: 0,
                }}
                show={errorToast}
              >
                <Toast.Body>Somthing went wrong!</Toast.Body>
              </Toast>
              <Alert
                variant="danger"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  textAlign: "center",
                }}
                show={errorAlert}
              >
                Somthing Went Wrong
              </Alert>
             <div
  className="background_element"
  style={{ backgroundColor: backgroundColor }}
>
  <div className="text_payments">
    <div className="header_payments_text">
      {PaymentImages && <img src={PaymentImages['Fintesa.png']} alt="Logo" className="logo" />}
      <p
        className="updated_text_payments"
        style={{
          color: textColor,
          fontSize: "2rem",
          marginTop: "1rem",
        }}
      >
        {product.name}
      </p>
      {!price.recurring ? (
        <h3
          className="price_text_payments"
          style={{ color: textColor }}
        >
          {currency.toUpperCase()}{" "}
          {(price_value * quantity).toFixed(2)}
        </h3>
      ) : (
        <h3
          className="price_text_payments"
          style={{ color: textColor }}
        >
          {currency.toUpperCase()} {price_value.toFixed(2)}
          {price.recurring.interval_count > 1
            ? "/ " +
              price.recurring.interval_count +
              " " +
              price.recurring.interval +
              "s"
            : ""}
          {price.recurring.interval &&
          price.recurring.interval_count == 1
            ? "/ " + price.recurring.interval
            : ""}
        </h3>
      )}
      {!price.recurring && (
        <QuantityInput
          showQuantityModal={setQuantityModalShow}
          quantity={quantity}
          price_value={price_value}
          textColor={textColor}
        />
      )}
      <p
        className="desc_text_payments"
        style={{ color: textColor }}
      >
        {product.description}
      </p>
      <div
        className="footer_payments_text"
        style={{ color: textColor }}
      >
        <span>
          Powered by{" "}
          <a
            target="_blank"
            href="https://fintesa.co/"
            style={{ color: textColor }}
            rel="noreferrer"
          >
            Fintesa
          </a>
        </span>{" "}
        |{" "}
        <span className="terms_footer_payments_text">
          <a
            href="https://fintesa.co/terms-and-conditions"
            target="_blank"
            style={{ color: textColor }}
            rel="noreferrer"
          >
            Terms
          </a>
        </span>
        {/* Edit when we have Privacy Page */}
        {/* <span>
              <a href="#" style={{color:textColor}}>Privacy</a>
            </span> */}
      </div>
    </div>
  </div>
</div>

              {paymentCompleted ? (
                <PaymentCompleted />
              ) : (
                <div className="form_payments">
                  {props.updateForm ? (
                    <>
                      <PayFormSkeleton />
                    </>
                  ) : (
                    <Elements
                      options={{ clientSecret: props.clientSecret }}
                      stripe={stripePromise}
                    >
                      <PayForm
                        payment_uuid={props.payment_uuid}
                        email={email}
                        currency={currency}
                      />
                    </Elements>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
