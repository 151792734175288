import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import Fintesa from "../images/Fintesa.png";
import AxiosInstanceUserServer from '../components/axios/AxiosInstanceUserServer';
import { useParams } from 'react-router-dom';
import PlansVertification from '../components/module/PlansVertification';
import { BiArrowBack } from "react-icons/bi";
import ImageContext from '../ImageContext';
export const FintesaEmail = (props) => {
    const param = useParams()
    const [verfied , setVerfied ] = useState(false)
    const [show , setShow ] = useState( false )
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [loading, setloading] = useState(false)
    const {PaymentImages} = useContext(ImageContext)

    useEffect(()=>{
        props.setEmail(email)
    }, [email])
    useEffect(()=>{
        props.setName(name)
    },[name])
    useEffect(()=> {
        props.setVerfied(verfied)
    }, [verfied])
    const handleSubmit = (e)=>{
        e.preventDefault()
        setloading(true)
        AxiosInstanceUserServer.post('/plans/send-verification-code',{email:email , product_id:param.productId} ).then( res => {
            setShow(true)
        }).catch( error => {
            console.error( error )
        }).finally(()=>{
            setloading(false)
        })
        // AxiosInstanceUserServer.get(`/plan/check-customer/${email}`).then( res => {
        //     console.log( res )
        // }).catch( error => {
        //     console.error(error)
        // })
    }
    // AxiosInstanceMENACartServer.post('/verify-email-code', {verification_code:'26783046', email:email}).then( res => {
    //     console.log( res.data )
    // })
  return (<>
            {show && <PlansVertification show={show} setShow={setShow} setVerfied={setVerfied} product_id={param.productId} email={email} />}
        <div style={{width:'100%', height:'100vh', display:'flex', placeContent:'center', alignItems:'center'}}>
            <Form style={{backgroundColor:'white', width:'100%', maxWidth:'500px', display:'flex',justifyContent:'space-evenly', alignItems:'center', minHeight:'400px', flexDirection:'column', padding:'3rem 2rem', borderRadius:'1rem'}} onSubmit={(e)=>{
                handleSubmit(e)
            }}>
                <div style={{alignSelf: "flex-start"}}>
                    <a style={{textDecoration: "none", color: "black", fontSize: "1.5rem"}} href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/pricing#subscription-plans`}>
                        <BiArrowBack />
                    </a>
                </div>
                <div style={{maxWidth:'25%'}}>
                    <img src={PaymentImages['Fintesa.png']} style={{maxWidth:'100%'}} />
                </div>
                <h2>Add your information</h2>
                <div style={{width:'80%'}}>
                <label htmlFor='email' style={{alignSelf:'flex-start'}}>Email</label>
                <Form.Control 
                required
                value={email}
                placeholder='Your account email'
                type='email'
                onChange={e=>{
                    setEmail(e.target.value)
                }}
                />
                 <label htmlFor='name' style={{alignSelf:'flex-start', marginTop:'1rem'}}>Name</label>
                <Form.Control 
                required
                value={name}
                placeholder='Your name'
                onChange={e=>{
                    setName(e.target.value)
                }}
                />
                </div>
                <Button style={{width:'40%', marginTop:'1.5rem  '}} type='submit' disabled={loading}>{loading? <Spinner variant='light' animation='border' size='sm' /> : 'Next' }</Button>
            </Form>
            {verfied && <h2>Verified</h2>}
        </div>
  </>)
}
