import React, { useContext } from 'react';
import FintesaLogo from "../../images/Fintesa.png";
import styles from "./InfoHeader.module.css";
import ImageContext from '../../ImageContext';


const InfoHeader = () => {
  const {PaymentImages} = useContext(ImageContext)

  return (
    <header className={styles.header}>
      <img src={PaymentImages['Fintesa.png']} alt='Fintesa Logo' loading='lazy' />
    </header>
  )
}

export default InfoHeader