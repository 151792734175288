import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosInstanceMENACartServer from "../components/axios/AxiosInstanceMENACartServer";
import AxiosInstanceUserServer from "../components/axios/AxiosInstanceUserServer";
import { ref  , getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import UpdatePaymentMethodForm from "../components/UpdatePaymentMethod/UpdatePaymentMethodConsumer";
import { Spinner } from "react-bootstrap";
import AddPaymentMethodForm from "../components/AddPaymentMethod/AddPaymentMethodForm";
import AddPaymentMethodConsumer from "../components/AddPaymentMethod/AddPaymentMethodConsumer";
import ImageContext from "../ImageContext";
const AddPaymentMethod = (props) => {
    const param = useParams()
    const [loading ,setLoading ] = useState(true)
    const [accountId , setAccountId ] = useState('')
    const [backgroundColor , setBackgroundColor ] = useState('#5469d4')
    const [textColor , setTextColor ] = useState('white')
    const [buttonColor , setButtonColor ] = useState('#5469d4')
    const [buttonTextColor, setButtonTextColor] = useState('white')
    const [logo , setLogo ] = useState('')
    const [paymentMethodUpdated , setPaymentMethodUpdated ] = useState(false)
    const {PaymentImages} = useContext(ImageContext)

    const subscriptionId =(param.customer)
    const customerId =(param.customer)
    useEffect(()=>{
    const extract_merchant_id_from_merchant_payment_uuid = async () => {
        setLoading(true);
        
          let res = await AxiosInstanceMENACartServer.get(`/get_merchent_id/${param.id}`).then( res => {
              setAccountId(res.data);
              return res.data;
          }).then(async(id) => {
           let res = await AxiosInstanceUserServer.get(`/branding/retrieve-branding/${id}`)
           return res.data
          }).then(async res => {
            setBackgroundColor(res.backgroundColor)
            setTextColor(res.textColor)
            setButtonTextColor(res.buttonTextColor)
            setButtonColor(res.buttonColor)
            if( res.selectedImage ){
                return await getDownloadURL( ref(storage , res.selectedImage)) 
            }else{
                return false
            }
          }).then( async res => {
            if( res ){
                setLogo(res)
            }
          }).catch( error => {
            // Handle Error
          }).finally(()=>{
            setLoading(false)
          });
        
      };
    extract_merchant_id_from_merchant_payment_uuid();
}, [])
    return ( <>
    <div className="update-payment-method-container" style={{backgroundColor:backgroundColor, color:textColor}}>
      { loading && <div style={{width:'100%', height:'100vh', display:'flex' , placeContent:'center', alignItems:'center', backgroundColor:'white'}}>
        <Spinner animation='border' variant="primary" />
      </div>}
       { !loading && <>
       <div className="product-container">
         { PaymentImages &&  <div style={{width:'6rem' , borderRadius:'50%'}}>
                <img src={PaymentImages['Fintesa.png']} style={{maxHeight:'100%', maxWidth:'100%',borderRadius:'50%'}}/>
            </div>}
    
        </div>
        { paymentMethodUpdated && <div style={{height:'315px',display:'flex',alignContent:'center',justifyContent:'center', flexDirection:'column', textAlign:'center'}}>
          <h2>Payment method Added successfully &#9989;</h2>
        </div> }
    { !paymentMethodUpdated &&   <div className="card-info-container simple-black-shadow" style={{marginTop:'3rem',backgroundColor:'white',color: "rgb(48,49,61)", padding:'2rem 2rem', borderRadius:'16px'}}>
            <h3 style={{marginBottom:'3rem', textAlign:'center'}}>Add payment method</h3>
{accountId && <AddPaymentMethodConsumer accountId={accountId} buttonTextColor={buttonTextColor} buttonColor={buttonColor} setPaymentMethodUpdated={setPaymentMethodUpdated}/>}
        </div>}
        </> }
    </div>
    </> );
}
 
export default AddPaymentMethod;