import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInput = ({
  phoneNumber,
  setPhoneNumber,
  payment_uuid,
  isValid,
}) => {
  const [isTouched, setisTouched] = useState(false);

  if(payment_uuid !== "Di6h_10a9fcb8-ab09-487d-80f5-69fa306ddd80") return <></>

  return (
    <div>
      <Form.Label
        htmlFor="phone"
        style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
      >
        Phone Number
      </Form.Label>
      <InputGroup className="mb-3">
        <PhoneInput
        containerStyle={{height:"40px"}}
        inputStyle={{height:"40px", width:'100%'}}
          country={"jo"}
          value={phoneNumber || ""}
          onChange={(value) => {
            setPhoneNumber(`+${value}`);
            setisTouched(true);
          }}
          isInvalid={isTouched && !isValid}
        />
        <Form.Control.Feedback type="invalid">
          Enter a Valid Phone Number
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
};

export default PhoneNumberInput;
