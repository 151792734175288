// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_Firebase,
  authDomain: process.env.REACT_APP_OUTH_DOMAIN_Firebase,
  projectId: process.env.REACT_APP_PROJECT_ID_Firebase,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_Firebase,
  messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID_Firebase,
  appId: process.env.REACT_APP_AOO_ID_Firebase  , 
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_Firebase
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
export { firestore, storage ,app };
