import React, { createContext, useState, useEffect } from "react";
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isStylesLoading, setIsStylesLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const specialCurrencies = [
    "bif",
    "clp",
    "djf",
    "gnf",
    "jpy",
    "kmf",
    "krw",
    "mga",
    "pyg",
    "rwf",
    "ugx",
    "vnd",
    "vuv",
    "xaf",
    "xof",
    "xpf",
  ];
  const [errorToast, setErrorToast] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#5469d4");
  const [buttonTextColor, setButtonTextColor] = useState("white");
  const [backgroundColor, setBackgroundColor] = useState("#5469d4");
  const [textColor, setTextColor] = useState("white");
  const [logo, setLogo] = useState("");
  const [termsAndConditions, setTermAndConditions] = useState("");
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [productId, setProductId] = useState("");
  const [priceId, setPriceId] = useState("");
  const [product, setProduct] = useState({});
  const [price, setPrice] = useState({});
  const [success_url, setSuccess_url] = useState("");
  const [quantityModalShow, setQuantityModalShow] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const updateAccId = (data) => {
    setAccountId(data);
  };

  const updateProdId = (data) => {
    setProductId(data);
  };

  const updatePriceId = (data) => {
    setPriceId(data);
  };
  

  const getBranding = async () => {
    if (!accountId) return;
    try {
      const res = await AxiosInstanceUserServer.get(
        `/branding/retrieve-branding/${accountId}`
      );
      const data = res.data;
      setButtonBackgroundColor(data.buttonColor);
      setButtonTextColor(data.buttonTextColor);
      setBackgroundColor(data.backgroundColor);
      setTextColor(data.textColor);

      if (data.selectedImage) {
        const url = await getDownloadURL(ref(storage, data.selectedImage));
        setLogo(url);
      }
    } catch (error) {
      setErrorToast(true);
      setTimeout(() => {
        setErrorToast(false);
      }, 3000);
    } finally {
      setIsStylesLoading(false);
    }
  };
  const get_terms_and_conditions = async () => {
    if (accountId) {
      await AxiosInstanceUserServer.get(
        `/merchant/get-terms-and-condtions/${accountId}`
      )
        .then((res) => {
          setTermAndConditions(res.data);

          if (!res.data) {
            setIsChecked(true);
          }
        })
        .catch((error) => {
          // To be Change by aws To Message (Toast)
          setErrorToast(true);
          setTimeout(() => {
            setErrorToast(false);
          }, 3000);
          setErrorAlert(true);
          setIsChecked(false);
          setTermsAndConditionsError(true);
        });
    }
  };

  useEffect(() => {
    getBranding();
    get_terms_and_conditions();
  }, [accountId, productId, priceId]);

  useEffect(() => {
    const get_product = async () => {
      setIsLoading(true);
      try {
        await AxiosInstanceMENACartServer.post(`/get-product`, {
          product_id: productId,
          price_id: priceId,
          merchant_id: accountId,
        }).then((res) => {
          setProduct(res.data["product"]);
          setPrice(res.data["price"]);
          if (res.data && res.data.product.metadata.success_url) {
            setSuccess_url(res.data.product.metadata.success_url);
          }
        });
      } catch (error) {
        // To be Change by aws To Message (Toast)
        setErrorToast(true);
        setTimeout(() => {
          setErrorToast(false);
        }, 3000);
      }
      setIsLoading(false);
    };

    if (accountId) {
      get_product();
    }
  }, [accountId, productId, priceId]);

  return (
    <PaymentContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isStylesLoading,
        setIsStylesLoading,
        isChecked,
        setIsChecked,
        paymentCompleted,
        setPaymentCompleted,
        specialCurrencies,
        errorToast,
        setErrorToast,
        errorAlert,
        setErrorAlert,
        buttonBackgroundColor,
        setButtonBackgroundColor,
        backgroundColor,
        setBackgroundColor,
        buttonTextColor,
        setButtonTextColor,
        textColor,
        setTextColor,
        logo,
        setLogo,
        termsAndConditions,
        setTermAndConditions,
        termsAndConditionsError,
        setTermsAndConditionsError,
        updateAccId,
        updateProdId,
        updatePriceId,
        product,
        price,
        success_url,
        accountId,
        productId,
        priceId,
        quantityModalShow,
        setQuantityModalShow,
        quantity, setQuantity,
        
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
