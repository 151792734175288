import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CheckoutForm from "./CheckoutForm";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { PaymentContext } from "./PaymentContext";

export default function PaymentIntent() {
  const { updateAccId, updateProdId, updatePriceId,quantity, setQuantity } =
    useContext(PaymentContext);
  const [clientSecret, setClientSecret] = useState("");
  const [account_id, setAccountId] = useState("");
  const { payment_uuid, product_id, price_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);

  const createPaymentIntent = async (quantity) => {
    setUpdateForm(true);
    if (account_id) {
      try {
        let res = await AxiosInstanceMENACartServer.post(
          `/create-payment-intent`,
          { product_id, quantity, price_id, merchant_id: account_id }
        );
        setClientSecret(res.data.clientSecret);
      } catch (error) {
        // Handle the error
      }
      setUpdateForm(false);
    }
  };

  async function LoadStripe() {
    let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
    return await loadStripe(stripe_publish_key, {
      stripeAccount: account_id,
    });
  }

  const stripePromise = LoadStripe();

  useEffect(() => {
    const extract_merchant_id_from_merchant_payment_uuid = async () => {
      setIsLoading(true);
      if (payment_uuid) {
        try {
          let res = await AxiosInstanceMENACartServer.get(
            `/get_merchent_id/${payment_uuid}`
          );
          setAccountId(res.data);
        } catch (error) {
          // Handle the error
        }
        setIsLoading(false);
      }
    };
    extract_merchant_id_from_merchant_payment_uuid();
  }, [payment_uuid]);

  useEffect(() => {
    if (account_id) {
      createPaymentIntent(quantity);
    }
  }, [account_id, quantity, product_id, price_id]);

  useEffect(() => {
    if (account_id) {
      updateAccId(account_id);
      updateProdId(product_id);
      updatePriceId(price_id);
    }
  }, [
    account_id,
    product_id,
    price_id,
    updateAccId,
    updateProdId,
    updatePriceId,
  ]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  const payment_info = {
    account_id,
    product_id,
    price_id,
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        clientSecret && (
          <CheckoutForm
            options={options}
            stripePromise={stripePromise}
            clientSecret={clientSecret}
            payment_info={payment_info}
            account_id={account_id}
            payment_uuid={payment_uuid}
            createPaymentIntent={createPaymentIntent}
            updateForm={updateForm}
          />
        )
      )}
    </div>
  );
}
