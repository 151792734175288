import React, { useState, useEffect } from "react";

import { Modal, Form, Button } from "react-bootstrap";

const QuantityModal = (props) => {
  const [show, setShow] = useState(props?.show);
  const [quantityValue, setQuantityValue] = useState(props?.quantity);
  const [disabled, setDisabled] = useState(false);
  const [exception, setException] = useState("");
  const handleClose = () => props.setShow(false);
  const handleUpdate = (event) => {
    event.preventDefault();
    if (
      (exception === "" && quantityValue * props?.price_value > 999999.99) ||
      (exception === "" && quantityValue > 99)
    ) {
      setDisabled(true);
    } else if (
      (exception === "jod" && quantityValue * props?.price_value > 709191.18) ||
      (exception === "jod" && quantityValue > 99)
    ) {
      setDisabled(true);
    } else if (
      (exception === "idr" &&
        quantityValue * props?.price_value > 9999999999.99) ||
      (exception === "idr" && quantityValue > 99)
    ) {
      setDisabled(true);
    } else {
      if (props?.updatePaymentIntent) {
        props.updatePaymentIntent(quantityValue);
      }
      props.setQuantity(quantityValue);
      if (props?.createPaymentIntent) {
        props.createPaymentIntent(quantityValue);
      }
      handleClose();
    }
  };

  useEffect(() => {
    if (props?.price?.currency === "jod") {
      setException("jod");
    } else if (props?.price?.currency === "idr") {
      setException("idr");
    }
  }, []);

  const handleChange = (e) => {
    setQuantityValue(Number(e.target.value));
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>Update Quantity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form style={{ margin: "0" }} onSubmit={handleUpdate}>
          <Form.Control
            type="number"
            min="1"
            step="1"
            defaultValue={props?.quantity}
            onChange={handleChange}
          />
          <Button type="submit" className="mb-2">
            Update
          </Button>
          {disabled ? (
            <>
              <span className="text-muted m-1">
                <strong>Warning!</strong> Quantity Exceed The Limit
              </span>
            </>
          ) : null}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default QuantityModal;
